import "animate.css"
import "./styles/global.scss"
import "./App.css"
import CryptoSwap from "./components/CryptoSwap";
import logo from "./assets/icons/logo.svg"
import pdf from "./assets/docs.pdf"
import {ConnectKitButton} from "connectkit";
import {useState} from "react";

function App() {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <main>
      <header className="header">
        <img src={logo} alt="logo" className="logo"/>
        <div className='connectkit-btn'><ConnectKitButton label='Connect Wallet'/></div>
      </header>
      <CryptoSwap/>
      <footer className="footer">
        <div className='footer-content'>
          <div className='footer-left'>
            <p>© Mupo Foundation - 2024</p>
            <strong className='footer-option'>Beta v1.2.7</strong>
            <a href={pdf} className='footer-option'>
              Docs
            </a>
            <a target='_blank' href="https://medium.com/@bocerrienison/mupo-foundation-revolutionizing-crypto-swaps-fec28eb1d61d" className='footer-option'>
              Medium
            </a>
            <a href="mailto:support@mupo.foundation" className='footer-option'>
              Support
            </a>
          </div>
        </div>
      </footer>
    </main>
  );
}

export default App;
