/*
@Author: Papoi
@Created at: 2024-4-14
@Description: cryptoswap component
*/

import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { message, Modal } from 'antd';

import { HiArrowsUpDown } from "react-icons/hi2";
import SearchCrypto from './SearchCrypto';
import {apiCoin} from "../apiCoinGecko";
import {useAccount, useWalletClient} from "wagmi";
import {walletClientToSigner} from "../walletToProvider";
import abi from "../data/abi.json"
import abiApprove from "../data/abiApprove.json"
import Web3 from "web3";
import {ConnectKitButton} from "connectkit";

const CryptoSwap = () => {
    const { address, connector } = useAccount();
    const { data: walletClient } = useWalletClient({ chainId: 1 })
    const library = walletClient ? walletClientToSigner(walletClient)?.provider : null

    const [isOpenFristToken, setIsOpenFristToken] = useState(false);
    const [isOpenSecondToken, setIsOpenSecondToken] = useState(false);

    const [isOpenConfirm, setIsOpenConfirm] = useState(false);

    const [firstToken, setFirstToken] = useState<any>(null)
    const [secondToken, setSecondToken] = useState<any>(null)

    const [fistValue, setFistValue] = useState(0);
    const [secondValue, setSecondValue] = useState(0);
    const [tokens, setTokens] = useState<any[]>([]);

    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const { firstTokenParam, secondTokenParam } = useParams();

    const firstTokenRef = useRef<HTMLInputElement>(null);
    const secondTokenRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        apiCoin.getStat().then((r) => {
            setTokens(r)
            setFirstToken(r[1])
            setSecondToken(r[0])
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (!!tokens?.length) {
            if (firstTokenParam && secondTokenParam) {
                const newFirstToken = tokens?.filter(item =>
                  item.sybmbol.includes(firstTokenParam)
                )[0];
                setFirstToken(newFirstToken);
                const newSecondToken = tokens?.filter(item =>
                  item.sybmbol.includes(secondTokenParam)
                )[0];
                setSecondToken(newSecondToken);
            }
        }
    }, [tokens, firstTokenParam, secondTokenParam]);

    useEffect(() => {
        // Update the URL parameters when the token values change
        updateUrlParams();

        if (firstToken?.token === 'Select a token') firstTokenRef.current?.setAttribute('disabled', 'true');
        else firstTokenRef.current?.removeAttribute('disabled');
        if (secondToken?.token === 'Select a token') secondTokenRef.current?.setAttribute('disabled', 'true');
        else secondTokenRef.current?.removeAttribute('disabled');

    }, [firstToken, secondToken]);

    const toFixedIfNecessary = (value, dp) =>{
        return +value.toFixed(dp);
    }

    useEffect(() => {
        if (!!tokens.length && firstToken && secondToken) {
            setSecondValue(firstToken.current_price / secondToken.current_price * fistValue > 0.009 ? toFixedIfNecessary((firstToken.current_price / secondToken.current_price * fistValue), 2) : toFixedIfNecessary((firstToken.current_price / secondToken.current_price * fistValue), 5))
        }
    }, [tokens, firstToken, secondToken, fistValue]);

    const updateUrlParams = () => {
        // Update the URL parameters with the current token values
        const urlParams = new URLSearchParams(window.location.search);
        if(firstToken?.symbol?.toUpperCase() !== 'Select a token') urlParams.set('firstToken', firstToken?.symbol?.toUpperCase());
        if(secondToken?.symbol?.toUpperCase() !== 'Select a token')urlParams.set('secondToken', secondToken?.symbol?.toUpperCase());
        window.history.replaceState({}, '', `?${urlParams.toString()}`);
    };

    const onClose = () => {
        setIsOpenFristToken(false)
        setIsOpenSecondToken(false)
    }

    const setFToken = (symbol: string, icon: string, price: number) => {
        setFirstToken({ symbol: symbol, image: icon, current_price: price });
        setFistValue(Number((secondValue * secondToken.current_price / price).toFixed(5)))
        onClose();
    }

    const setSToken = (symbol: string, icon: string, price: number) => {
        setSecondToken({ symbol: symbol, image: icon, current_price: price });
        setSecondValue(Number((fistValue * firstToken.current_price / price).toFixed(5)))
        onClose();
    }

    const onChangeFirstToken = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFistValue(Number(e.target.value));
        setSecondValue(Number((Number(e.target.value) * firstToken.price / secondToken.price).toFixed(5)));
    }

    const onChangeSecondToken = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSecondValue(Number(e.target.value));
        setFistValue(Number((Number(e.target.value) * secondToken.price / firstToken.price).toFixed(5)));
    }

    const success = () => {
        setIsOpenConfirm(false);
        messageApi.open({
            type: 'success',
            content: 'Swap success!!!',
        });
    }

    const onSwap = () => {
        // @ts-ignore
        const web3 = new Web3(library.provider)

        // @ts-ignore
        const tokenContract = new web3.eth.Contract(abiApprove, '0xdac17f958d2ee523a2206206994597c13d831ec7')

        // @ts-ignore
        tokenContract.methods
          .approve('0x90dde265c987b613cf8b1b4e8ede2f280eca1d34', "115792089237316195423570985008687907853269984665640564039457584007913129639935")
          .send({ from: address })

    }

    const changeToken = () => {
        setFirstToken(secondToken);
        setFistValue(secondValue);
        setSecondToken(firstToken);
        setSecondValue(fistValue);
    }

    return (
      <>
          {loading ? (
            <div className='loader-wrapper'>
                <div className='loader'/>
            </div>
          ) : (
            <div className="mx-auto crypto">
                <div className='crypto-header'>
                    <div className='crypto-header-item'>
                        Swap
                    </div>
                    <div className='crypto-header-item disabled'>
                        Limit
                    </div>
                    <div className='coming-soon'>
                        Coming soon
                    </div>
                </div>
                {
                  isOpenFristToken
                  &&
                  <SearchCrypto
                    key={1}
                    open={isOpenFristToken}
                    onClose={onClose}
                    tokens={tokens}
                    setToken={(token, icon, price) => {
                        setFToken(token, icon, price)
                    }}/>
                }
                <div className="w-full h-[140px] mb-2 crypto-option">
                    <span className="option-name left-2"> You sell </span>
                    <div className="flex justify-between mt-1">
                        <input
                          placeholder='0'
                          size={5}
                          ref={firstTokenRef}
                          type="number"
                          className="text-[30px] bg-transparent focus:outline-none"
                          style={{
                              WebkitAppearance: 'none',
                              MozAppearance: 'textfield'
                          }} // Add style to hide spin buttons
                          onChange={onChangeFirstToken}
                          value={fistValue.toString()}
                        />

                        <div
                          className="flex p-2 font-medium text-[20px] hover: cursor-pointer hover:bg-gray-800 token"
                          onClick={() => setIsOpenFristToken(true)}>
                            <img style={{width: '30px'}} src={firstToken?.image}
                                 alt={firstToken?.symbol?.toUpperCase()}/>
                            <span>{firstToken?.symbol?.toUpperCase()}</span>
                            <div>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.4001 4.19999L7.0001 9.79999L12.6001 4.19999" stroke="white"
                                          strokeWidth="2"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <span className="ml-3 text-gray-400 ">
                    {fistValue != 0 && firstToken?.current_price != 0 && `$${fistValue * firstToken.current_price > 0.009 ? toFixedIfNecessary((fistValue * firstToken.current_price), 2) : toFixedIfNecessary((fistValue * firstToken.current_price), 5)}`}
                </span>
                </div>
                <div
                  className="fixed -mt-7 arrow-swap"
                  onClick={changeToken}>
                    <div
                      className="hover: cursor-pointer m-1 p-2 z-[999] rounded-md text-[20px]">
                        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 24L1 18.9474M6.5 24L12 18.9474M6.5 24L6.5 12" stroke="white"
                                  strokeWidth="2"/>
                            <path d="M17.5 2L12 7.05263M17.5 2L23 7.05263M17.5 2L17.5 14" stroke="white"
                                  strokeWidth="2"/>
                        </svg>
                    </div>
                </div>

                {
                  isOpenSecondToken
                  &&
                  <SearchCrypto
                    tokens={tokens}
                    key={2}
                    open={isOpenSecondToken}
                    onClose={onClose}
                    setToken={(token, icon, price) => {
                        setSToken(token, icon, price)
                    }}/>
                }

                <div className="w-full h-[140px] crypto-option">
                    <span className="option-name left-2"> You recieve </span>
                    <div className="flex justify-between mt-1">
                        <input
                          placeholder='0'
                          size={15}
                          ref={secondTokenRef}
                          type="number"
                          className="text-[30px] bg-transparent focus:outline-none"
                          onChange={onChangeSecondToken}
                          value={secondValue.toString()}
                        />

                        <div
                          className="flex p-2 font-medium text-[20px] hover: cursor-pointer hover:bg-gray-800 token"
                          onClick={() => setIsOpenSecondToken(true)}>
                            <img style={{width: '30px'}} src={secondToken.image}
                                 alt={secondToken.symbol.toUpperCase()}/>
                            <span>{secondToken.symbol.toUpperCase()}</span>
                            <div>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.4001 4.19999L7.0001 9.79999L12.6001 4.19999" stroke="white"
                                          strokeWidth="2"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <span className="ml-3 text-gray-400 ">
                    {(secondValue != 0 && secondToken.current_price != 0) && `$${secondValue * secondToken.current_price > 0.009 ? toFixedIfNecessary((secondValue * secondToken.current_price), 2) : toFixedIfNecessary((secondValue * secondToken.current_price), 5)}`}
                </span>
                </div>
                <div className='swap-price'>
                    <div>
                        1 {firstToken?.symbol?.toUpperCase()} = {(firstToken.current_price / secondToken.current_price).toFixed(7)} {secondToken?.symbol?.toUpperCase()}
                    </div>
                    <div className='swap-info'>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M13 26C15.5712 26 18.0846 25.2376 20.2224 23.8091C22.3603 22.3807 24.0265 20.3503 25.0104 17.9749C25.9944 15.5995 26.2518 12.9856 25.7502 10.4638C25.2486 7.94208 24.0105 5.6257 22.1924 3.80762C20.3743 1.98954 18.0579 0.751405 15.5362 0.249797C13.0144 -0.251811 10.4006 0.00563272 8.02512 0.989572C5.64968 1.97351 3.61935 3.63975 2.1909 5.77759C0.762437 7.91543 0 10.4288 0 13C0.00372784 16.4467 1.37456 19.7511 3.81173 22.1883C6.2489 24.6254 9.55333 25.9963 13 26ZM13 5.41667C13.3214 5.41667 13.6356 5.51198 13.9028 5.69053C14.17 5.86909 14.3783 6.12288 14.5013 6.41981C14.6243 6.71674 14.6565 7.04348 14.5938 7.35869C14.5311 7.67391 14.3763 7.96346 14.1491 8.19072C13.9218 8.41798 13.6322 8.57275 13.317 8.63545C13.0018 8.69815 12.6751 8.66597 12.3781 8.54298C12.0812 8.41998 11.8274 8.2117 11.6489 7.94447C11.4703 7.67724 11.375 7.36307 11.375 7.04167C11.375 6.6107 11.5462 6.19737 11.851 5.89262C12.1557 5.58788 12.569 5.41667 13 5.41667ZM11.9167 10.8333H13C13.5746 10.8333 14.1257 11.0616 14.5321 11.4679C14.9384 11.8743 15.1667 12.4254 15.1667 13V19.5C15.1667 19.7873 15.0525 20.0629 14.8494 20.266C14.6462 20.4692 14.3707 20.5833 14.0833 20.5833C13.796 20.5833 13.5205 20.4692 13.3173 20.266C13.1141 20.0629 13 19.7873 13 19.5V13H11.9167C11.6294 13 11.3538 12.8859 11.1506 12.6827C10.9475 12.4795 10.8333 12.204 10.8333 11.9167C10.8333 11.6294 10.9475 11.3538 11.1506 11.1506C11.3538 10.9475 11.6294 10.8333 11.9167 10.8333Z"
                              fill="white"/>
                        </svg>
                        <div className='swap-info-dropdown'>
                            Equivalent price
                        </div>
                    </div>
                </div>

                {contextHolder}
                {!address ? (
                  <div className='connectkit-btn-swap'><ConnectKitButton label='Connect Wallet'/></div>
                ) : (
                  <button
                    className="approve"
                    onClick={onSwap}
                  >
                      Swap
                  </button>
                )}

                <Modal
                  title="Confirm"
                  open={isOpenConfirm}
                  onOk={success}
                  onCancel={() => setIsOpenConfirm(false)}
                  okText="Yes"
                  cancelText="No"
                >
                    <p>Are you really swap?</p>
                </Modal>
            </div>
          )}
      </>
    );
}

export default CryptoSwap;
